import { gql } from '@apollo/client';

const MENU_v1 = gql`query SideMenu { sideMenu{ itemId,  title, addnew,  inid, id, groupid, sidegroup } } `;
const USER_STATUS = gql`query UserStatus { userStatus{ inid, id } } `;
const SIGN_IN = gql`
query SignIn($username: String!, $password: String!, $domain: String) {
signIn(username: $username, password: $password, domain: $domain) {
msg
status
address
description
}
}
`;
const RESET_PASSWORD = gql`
query Password($username: String!, $otp: String!, $password: String!, $confirmpassword: String!, $frmMode: Int!) {
password(username: $username, otp: $otp, password: $password, confirmpassword: $confirmpassword, frmMode:$frmMode) {
msg
status
}
}
`;
//const GET_REQUEST = gql`query GetRequest {  getList(listid: 16){ itemId, status, total } } `;
/*
const GET_LIST = gql`
query Get_List ($listid: Int!, $arg1: String) {
getSiteList(listid: $listid, arg1: $arg1){

id,
inid,
title,
itemstatus,
gender,
email,
userid,
fieldtype,
masterid,
passowrd,
confirmpassword,
required,

description,
itemId,
status,

zip,
planid, 
phone, 

address,
city,
total,
country,
fullname

province,
date,
domain,
username,


addnew,
tbl,
rowid,

string1,
string2,
string3,
string4,
string5,

string6,
string7,
string8,
string9,
string10,

string11,
string12,
string13,
string14,
string15,

string16,
string17,
string18,
string19,
string20,

string21,
string22,
string23,
string24,
string25,

string26,
string27,	

name,
typeid,
subone {
s1itemId
s1inid
s1description
s1title
s1status
s1id
}
}
}
`;

const GET_COUNTRY = gql`query GetCountry($listid: Int) { getList(listid: $listid){ itemId, inid, title, id } }`;
*/
const DEF_SITE_LIST = gql`
query DefSiteList ($listid: Int!, $arg1: String, $arg2: Int, $arg3: String) {
  getSiteList(listid: $listid, arg1: $arg1 , arg2: $arg2, arg3: $arg3){
id,
inid,
title,
description,
itemId,
status,
password,
zip,
planid, 
phone, 
address,
city,
total,
country,
fullname
province,
date,
domain,
username,
city,
addnew,
tbl,
broken,
gender, 
string1,
string2,
string3,
string4,
string5,
string6,
string7,
string8,
string9,
string10,

string11,
string12,
string13,
string14,
string15,
string16,
string17,
string18,
string19,
string20,

string21,
string22,
string23,
string24,
string25,
string26,
string27,	
name,
typeid,
subone {
s1itemId
s1inid
s1description
s1title
s1status
s1id
}
}
}
`;


export { MENU_v1, RESET_PASSWORD, SIGN_IN, DEF_SITE_LIST }
