/* eslint-disable */ 
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
//import DomainBG from '../../assets/domain.png';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
//import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
//import SendIcon from '@mui/icons-material/Send';


import Button from '@mui/material/Button';
import {AddToCart} from '../../components/utility/utility';
import BizTrans from '../../assets/biztrans.png';
import Optima from '../../assets/optima.png';
import Cyber from '../../assets/cyber.png';
import Migration from '../../assets/migration.png';
import Analytics from '../../assets/analytics.png';
import Custom from '../../assets/custom.png';
//import Mgt from '../../assets/mgt.png';
import {NotFound} from '../../components/utility/utility';
//import { DEF_SITE_LIST } from '../../components/gpl/gpl';
//import {useQuery} from '@apollo/client';
import Divider from '@mui/material/Divider';
import Carousel from 'react-bootstrap/Carousel';
import VisibilityIcon from '@mui/icons-material/Visibility';

function uuidv4() { 
return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
.replace(/[xy]/g, function (c) {
const r = Math.random() * 16 | 0, 
v = c == 'x' ? r : (r & 0x3 | 0x8);
return v.toString(16);
});
}

export default function Home(props) { 
const numbersHosting = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117; });
const numbersServers = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 118; });

let hostingFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 117 ||parseInt(currItem.planid) == 0; });
let serverFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 118 ||parseInt(currItem.planid) == 0; });

hostingFeatures = hostingFeatures.sort((a, b) => a.total - b.total);
serverFeatures = serverFeatures.sort((a, b) => a.total - b.total);

const [formState, setFormState] = useState({
slideState: 0,
slideContent: 0,
});


const numbersMainPromo = props.pages.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == 13; });
const numbersRigthPromo = props.pages.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == 12; });

let promos = '';
for (let i = 0; i < numbersMainPromo.length; i++) {   
promos= promos+`<Carousel.Item>
${numbersMainPromo[0].description}
</Carousel.Item>`;
}

if(numbersMainPromo.length != formState.slideContent){setFormState({ ...formState, slideContent: numbersMainPromo.length });}

let rigthPromo =(<><h5 className='bg-secondary p-1 pt-2 pb-2 text-center text-white'>{numbersRigthPromo[0].string1}</h5>																									<section dangerouslySetInnerHTML={{__html: numbersRigthPromo[0].description}}></section>
</>);

function passPrdId(prdId){
let listhostingFeatures = hostingFeatures.map((number, index) => 
<section key={uuidv4()+''+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
{(getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) == 0) ? 'Unlimited' : <>{getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId)} {number.zip}</>}
 
</section>
</section>
</section>
);
return listhostingFeatures;
}

function passServId(prdId){
let listserverFeatures = serverFeatures.map((number, index) => 
<section key={window.uuidv4()+''+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
<span className='text-end'>{getFeatureValue(numbersServers,(number.fullname), serverFeatures, prdId)} {number.zip}</span>
</section>
</section>
</section>
);
return listserverFeatures;
}

function getFeatureValue(x,y,z,productid){
let colN = y;
function findNamesByFavoriteFood(list, favoriteFood) {
let fitem = list.filter((obj) => obj[colN] != '');//.map((obj) => obj.name);
let sitem = fitem.filter((obj) => (obj.itemId == productid || obj.inid == productid)).map((obj) => obj[colN]);
return sitem;}
return (findNamesByFavoriteFood(x, y));
}

function productHeaderClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('bg-success text-white p-2 text-center');
case 1:  
return ('bg-primary text-white p-2 text-center');
case 2:  
return ('bg-info text-white p-2 text-center');
case 3: 
return ('bg-secondary text-white p-2 text-center');
default:
return ('bg-primary text-white p-2 text-center');
}
}

function productServersClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('server1 text-white p-2 text-center');
case 1:  
return ('server2 text-white p-2 text-center');
case 2:  
return ('server3 text-white p-2 text-center');
case 3: 
return ('server4 text-white p-2 text-center');
default:
return ('server5 text-white p-2 text-center');
}
}


let listHosting = numbersHosting.map((number, index) => 
<section className='col-sm-6 col-lg-3 px-3 mb-5' key={window.uuidv4()+''+number.id}>
<Card>
<CardActionArea>
<h5 className={productHeaderClass(index)}>{number.string1}</h5>
<CardContent>
<section className='mb-3 mt-2 text-center'>
<AddToCart prdname={`${number.string1} Web Hosting`} type='hosting' cart currency={props.currency} itemId={number.inid == 0 ? number.itemId : number.inid} price={number.string4} updateCart={props.updateCart} /></section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passPrdId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</section>
);

let listServers = numbersServers.map((number, index) => 
<section className='col-sm-6 col-lg-3 px-3 mb-5' key={window.uuidv4()+''+number.id}>
<Card>
<CardActionArea>
<h5 className={productServersClass(index)}>{number.string1}</h5>
<CardContent>
<section className='mb-3 mt-2 text-center'>
<AddToCart prdname={`${number.string1} VPS`} type='server' cart currency={props.currency} itemId={number.inid == 0 ? number.itemId : number.inid} price={number.string4} updateCart={props.updateCart} /></section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passServId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</section>
);

return (
<section className='mt-4'>
<section className='defMrg'>
{props.NotFound ? <section className='d-flex justify-content-center'><NotFound /></section> : null}
<section className='row mb-3'>
<section className='col-12 col-lg-9 px-4 mb-3'>
<Carousel>
{numbersMainPromo[0].string3 ? <Carousel.Item><section dangerouslySetInnerHTML={{__html: numbersMainPromo[0].string3}}></section></Carousel.Item> : null}
{numbersMainPromo[1].string3 ? <Carousel.Item><section dangerouslySetInnerHTML={{__html: numbersMainPromo[1].string3}}></section></Carousel.Item> : null}
{numbersMainPromo[2].string3 ? <Carousel.Item><section dangerouslySetInnerHTML={{__html: numbersMainPromo[2].string3}}></section></Carousel.Item> : null}
</Carousel>
</section>
<section className='col-lg-3 px-3 d-none d-lg-block mb-3'>
{rigthPromo}
</section>
</section>
</section>

<section className='defMrg'>
<h4 className='mb-3'>
<section className="d-flex align-items-center">
<span className="mx-2">Web Hosting</span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center">
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: `/cat/117/85/hosting`, }}  state={{ pageTitle : 'title', }}>
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small" endIcon={<VisibilityIcon fontSize="small" />}>View all features</Button>
</Link>
</div>
</section>
</section>
</h4>
<section className='row'>{listHosting}</section>
</section>


<section className='bg-white defPad'>
<h4 className='mb-3'>
<section className="d-flex align-items-center p-1">
<span className="mx-2">Servers</span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center">
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: `/cat/118/100/servers`, }}  state={{ pageTitle : 'title', }}>
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small" endIcon={<VisibilityIcon fontSize="small" />}>View all features</Button>
</Link>
</div>
</section>
</section>
</h4>
<section className='row'>{listServers}</section>
</section>
<Divider className='pb-5 defMrg' />

<section className='consultancy defPad pt-5 pb-5'>
<h4 className='col-12 mb-3'>Information Technology Consultancy</h4>
<h6 className='mb-4'>Let us take care of your digital solutions need while you concentrate on your core business  goals</h6>


<section className='row'>
<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Digital Transformation Strategy' title="Digital Transformation Strategy" src={BizTrans} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Digital Transformation Strategy
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Unlock the full potential of your business with our Digital Transformation Strategy service. We work with you to identify key areas for digital improvement, design a tailored transformation roadmap, and implement cutting-edge technologies to streamline operations and enhance customer experiences. Our approach ensures that your organization stays ahead of the curve in a rapidly evolving digital landscape</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center>
</Card>
</section>

<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='IT Infrastructure Optimization' title="IT Infrastructure Optimization" src={Optima} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
IT Infrastructure Optimization 
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Maximize the efficiency and performance of your IT infrastructure with our Optimization service. We analyze your current systems, recommend improvements, and implement best practices to enhance scalability, security, and reliability. From server management to network enhancements, our solutions are designed to ensure your infrastructure supports your business goals effectively</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Cybersecurity Assessment and Solutions' title="Cybersecurity Assessment and Solutions" src={Cyber} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Cybersecurity Assessment and Solutions
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Protect your business from cyber threats with our comprehensive Cybersecurity Assessment and Solutions. We conduct thorough assessments to identify vulnerabilities, develop robust security protocols, and implement advanced threat detection systems. Our solutions are tailored to safeguard your data and ensure compliance with industry standards</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Cloud Solutions and Migration' title="Cloud Solutions and Migration" src={Migration} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Cloud Solutions and Migration
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Transition to the cloud seamlessly with our Cloud Solutions and Migration service. We provide end-to-end support, from selecting the right cloud platform to migrating your data and applications. Our solutions offer increased flexibility, cost efficiency, and scalability, ensuring that your business leverages the full potential of cloud technology</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 px-2 mb-5 d-lg-none'>
<Card className='m-1'>
<img loading='lazy' alt='Business Intelligence and Analytics' title="Business Intelligence and Analytics" src={Analytics} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Business Intelligence and Analytics
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Transform your data into actionable insights with our Business Intelligence and Analytics service. We implement advanced BI tools and analytics platforms to help you make informed decisions. Our solutions include data visualization, predictive analytics, and reporting to drive strategic business growth and operational efficiency</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>


<section className='col-sm-6 px-2 mb-5 d-lg-none'>
<Card className='m-1'>
<img loading='lazy' alt='Custom Software Development' title="Custom Software Development" src={Custom} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Custom Software Development
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Tailor-made solutions to meet your unique business needs with our Custom Software Development service. Our team works closely with you to design, develop, and deploy software applications that align with your operational requirements. Whether it&acute;s a bespoke application or system integration, we deliver solutions that enhance your business processes</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



</section>
</section>
</section>
);}