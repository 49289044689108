/* eslint-disable */ 
import { useState } from "react";
import { Link } from 'react-router-dom';
import { Input }  from '../../components/form/form';
import { useLazyQuery } from '@apollo/client';
import { RESET_PASSWORD }  from '../../components/gpl/gpl';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';		 

export default function App(props) {
const [formState, setFormState] = useState({
username: '',
otp:"", 
password:"", 
confirmpassword:"",
frmMode: 0,
success: false
});

const [resetPassword, { loading, data, error }] = useLazyQuery(RESET_PASSWORD, {fetchPolicy: "no-cache" });

var info = null;
var infoMsg = "";
var infoStatus = -1;

if (loading){ info = <div className="alert alert-info" role="alert">Checking your username, please wait……………</div>; }
if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};
if (data) {
const numbers = data.password;
infoStatus = numbers[0].status;
infoMsg = numbers[0].msg;  

if(parseInt(numbers[0].status) === 1){ 
if(!formState.success){setFormState({ ...formState, success: true });}
info = <div className="alert alert-success" role="alert" dangerouslySetInnerHTML={createMarkup({infoMsg})}></div>;}
else if(parseInt(numbers[0].status) === 3){ 
if(parseInt(formState.frmMode) === 0){ 
console.log('update');
setFormState({ ...formState, frmMode: 1 });}
info = <div className="alert alert-info" role="alert" dangerouslySetInnerHTML={createMarkup({infoMsg})}></div>;}
  else if(parseInt(infoStatus) === 0){ 
info = <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={createMarkup({infoMsg})}></div>;
}}  
  function createMarkup() { return {__html: infoMsg}; }
  function handleChange (e){
    e.preventDefault();    
    const name = e.target.name
    setFormState({
      ...formState,
      [name]: e.target.value
    });
  }
  const [passwordShow, setpasswordShow] = useState("password");
      function passwordVisibility(e) {   
        e.preventDefault();    
        if(passwordShow === "password"){setpasswordShow("text"); return;}
        setpasswordShow("password"); return;  }
		
let successCtr;
if(!formState.success){successCtr = (<><section className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">OTP</span>
                        <Input name="otp" type="number" placeholder="OTP" value={formState.otp} handleChange={handleChange} required="required" />
                      </section>
					  
					  <section className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
						<input type="hidden" name="username" value={formState.username} />
                        <output className="p-2">{formState.username}</output>
                      </section>
					  
					  <section className="input-group mb-3">
                        <span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1">
                          <VisibilityOffIcon /></span>
                        <Input required="required" name="password" type={passwordShow} placeholder="Password" value={formState.password} handleChange={handleChange} title="Password must contain a number, special character, uppercase letter, lowercase letter and be 7 to 15 characters long" />
                      </section>
					  
					  <section className="input-group mb-3">
                        <span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1">
                          <VisibilityOffIcon /></span>
                        <Input required="required" name="confirmpassword" type={passwordShow} placeholder="Confirm Password" value={formState.confirmpassword} handleChange={handleChange} />
                      </section>
                      <section className="card-title">
                        <Input name="continueBtn" className="btn btn-success form-control" type="submit">
                          Continue
                        </Input>
                      </section></>);}
	if(formState.frmMode === 0){	
    return (
    <section className='d-flex align-items-center justify-content-center'> 
<section className='loginCon'> 
<form onSubmit={(e) => { e.preventDefault(); resetPassword({ variables: formState });}} >
          <main className="bg-white" id="mainLogInFrm">
            <section className="card bg-white">
              {info}
              <article className="card bg-white">
                <section className="card-header frmTitle">
                Set password
                </section>
				
				


                    <section className="card-body p-4">
                      <section className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
                        <Input name="username" type="email" placeholder="Username" value={formState.username} handleChange={handleChange} required="required" />
                      </section>
					  <input type="hidden" name="frmMode" value={formState.frmMode} />
                      <section className="card-title">
                        <Input name="continueBtn" className="btn btn-success form-control" type="submit">
                          Continue
                        </Input>
                      </section>
                      <section>
                      <Link to="/acct/109/Login" className="btn btn-light form-control">
                      Return to Login
                      </Link>
                      </section>  
                      
                    
                    </section>
                </article>
              </section>
          </main>
        </form></section></section>
  );
	}
	else if(formState.frmMode === 1){
		return (
        <form onSubmit={(e) => { e.preventDefault(); resetPassword({ variables: formState });}} >
         
	 <main className={(typeof props.pub === 'undefined' || props.pub === false) ? "wrapper" : ""}>
	<section className={(typeof props.pub === 'undefined' || props.pub === false) ? "frmWrapper" : ""}>
            <section>
              {info}
              <article className="card bg-white">
                <section className="card-header frmTitle">
                Reset password
                </section>
                    <section className="card-body p-4">
                      {successCtr}
                      <section>
                      <Link to="/acct/109/Login" className="btn btn-light form-control">
                      Return to Login
                      </Link>
                      </section>  
                    </section>
                </article>
              </section>
              </section>
          </main>
        </form>
  );
	}
}
