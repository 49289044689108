/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {PageTitle, ListData} from '../../components/utility/utility';
import { jwtDecode } from "jwt-decode";
import EditForm from '../../components/form/form';


import { styled } from '@mui/material/styles';
import DrawerJ from '@mui/joy/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';


import KeyIcon from '@mui/icons-material/Key';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArticleIcon from '@mui/icons-material/Article';
import PagesIcon from '@mui/icons-material/Pages';
//import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GroupsIcon from '@mui/icons-material/Groups';
//import WorkIcon from '@mui/icons-material/Work';
import DomainIcon from '@mui/icons-material/Domain';
import WebIcon from '@mui/icons-material/Web';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
//import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Inventory2Icon from '@mui/icons-material/Inventory2';

import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EmailIcon from '@mui/icons-material/Email';
import ListIcon from '@mui/icons-material/List';
import SellIcon from '@mui/icons-material/Sell';
import ImageIcon from '@mui/icons-material/Image';
import MoneyIcon from '@mui/icons-material/Money';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';

let curUser = null;
if(sessionStorage.getItem("signIn")){
curUser = jwtDecode(sessionStorage.getItem("signIn"));
}//} 


export default function Myacct(props) { 
const [selectedIndex, setSelectedIndex] = React.useState(1);
const handleListItemClick = (
event: React.MouseEvent<HTMLDivElement, MouseEvent>,
index: number, ) => { setSelectedIndex(index); };

const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

const [formState, setFormState] = useState({
display: 128,
addnew: 0,
dataimport: 0,
dataexport: 0,
manage: 0,
editData:0,
description:'',
city:'',
address:'',
province:'',
fullname:'',
username:'',
password:'',
name:'',
domain:'',
zip:'',
editfield:0,
pageTitle:'',


string1:'', string2:'', string3:'', string4:'', string5:'', string6:'',
string7:'', string8:'', string9:'', string10:'', string11:'', string12:'',
string13:'', string14:'', string15:'', string16:'', string17:'', string18:'',
string19:'', string20:'', string21:'', string22:'', string23:'', string24:'',
string25:'', string26:'', string27:'',
});

const getDefData = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(2), arg1:String(formState.display), arg2: parseInt(formState.addnew) }, fetchPolicy: "cache-first"});

useEffect(() => {
if (getDefData.data && getDefData.data.getSiteList.length > 0 && (formState.addnew != formState.manage) ) { 
setFormState({ ...formState, 
manage: formState.addnew,
zip: getDefData.data.getSiteList[0].zip,
domain: getDefData.data.getSiteList[0].domain,
name: getDefData.data.getSiteList[0].name,
city: getDefData.data.getSiteList[0].city,
address: getDefData.data.getSiteList[0].address,
province: getDefData.data.getSiteList[0].province,
fullname: getDefData.data.getSiteList[0].fullname,
username: getDefData.data.getSiteList[0].username,
password: getDefData.data.getSiteList[0].password,
description: getDefData.data.getSiteList[0].description,
string1: getDefData.data.getSiteList[0].string1,
string2: getDefData.data.getSiteList[0].string2,
string3: getDefData.data.getSiteList[0].string3,
string4: getDefData.data.getSiteList[0].string4,
string5: getDefData.data.getSiteList[0].string5,
string6: getDefData.data.getSiteList[0].string6,
string7: getDefData.data.getSiteList[0].string7,
string8: getDefData.data.getSiteList[0].string8,
string9: getDefData.data.getSiteList[0].string9,
string10: getDefData.data.getSiteList[0].string10,
string11: getDefData.data.getSiteList[0].string11,
string12: getDefData.data.getSiteList[0].string12,
string13: getDefData.data.getSiteList[0].string13,
string14: getDefData.data.getSiteList[0].string14,
string15: getDefData.data.getSiteList[0].string15,
string16: getDefData.data.getSiteList[0].string16,
string17: getDefData.data.getSiteList[0].string17,
string18: getDefData.data.getSiteList[0].string18,
string19: getDefData.data.getSiteList[0].string19,
string20: getDefData.data.getSiteList[0].string20,
string21: getDefData.data.getSiteList[0].string21,
string22: getDefData.data.getSiteList[0].string22,
string23: getDefData.data.getSiteList[0].string23,
string24: getDefData.data.getSiteList[0].string24,
string25: getDefData.data.getSiteList[0].string25,
string26: getDefData.data.getSiteList[0].string26,
string27: getDefData.data.getSiteList[0].string27,
});
}

});


function updateDisplay(display,addnew=0,dataimport=0,dataexport=0,manage=0,editData=0,editfield=0,title=''){
setFormState({ ...formState, display: display, addnew: addnew, dataimport: dataimport, dataexport: dataexport, manage: manage, editData:editData, editfield:editfield, pageTitle:title, });
}

function updateDelete(){
  updateDisplay(formState.display,formState.addnew,formState.dataimport,formState.dataexport,formState.manage)

}

function icon(id) { 
switch(parseInt(id) ? parseInt(id) : 0) {
case 131: 
return (<PagesIcon sx={{ mr: 1, }} />);
case 132: 
case 110: 
return (<ArticleIcon sx={{ mr: 1, }} />);
case 133: 
return (<InventoryIcon sx={{ mr: 1, }} />);
case 138: 
return (<ContactSupportIcon sx={{ mr: 1, }} />);
case 136: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 137: 
return (<GroupsIcon sx={{ mr: 1, }} />);
case 116: 
return (<DomainIcon sx={{ mr: 1, }} />);
case 117: 
return (<WebIcon sx={{ mr: 1, }} />);
case 118: 
return (<StorageIcon sx={{ mr: 1, }} />);
case 119: 
return (<SecurityIcon sx={{ mr: 1, }} />);
case 120: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 140: 
return (<DoDisturbOnIcon sx={{ mr: 1, }} />);
case 139: 
case 38: 
return (<ReceiptIcon sx={{ mr: 1, }} />);
//case 142: 
//return (<CreditCardIcon sx={{ mr: 1, }} />);
case 141:
case 142: 
return (<PaymentsIcon sx={{ mr: 1, }} />);
case 143: 
return (<EmailIcon sx={{ mr: 1, }} />);
case 4: 
return (<KeyIcon sx={{ mr: 1, }} />);
case 5: 
return (<PermContactCalendarIcon sx={{ mr: 1, }} />);
case 11: 
return (<ListIcon sx={{ mr: 1, }} />);
case 6: 
return (<SellIcon sx={{ mr: 1, }} />);
case 8: 
return (<ImageIcon sx={{ mr: 1, }} />);
case 7: 
return (<WidgetsIcon sx={{ mr: 1, }} />);
case 145: 
return (<MenuIcon sx={{ mr: 1, }} />);
case 23: 
return (<AccountTreeIcon sx={{ mr: 1, }} />);
default:
return null;
} 
}

const numbersPrimary = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 115; });
const numbersAdmin = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 129; });
const numbersBilling = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 3; });
const numbersAccountSettings = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 144; });

let adminMenu = numbersAdmin.map((number, index) => <span key={number.id}><ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); updateDisplay((number.inid == 0 ? number.itemId : number.inid),number.addnew,number.string1,number.string2,number.string3,'','',number.title)}}> {icon(number.inid == 0 ? number.itemId : number.inid)}<ListItemText primary={number.title} /></ListItemButton></ListItem><Divider />
</span>);

let myProducts = numbersPrimary.map((number, index) => <span key={number.id}><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); updateDisplay((number.inid == 0 ? number.itemId : number.inid),number.addnew,number.string1,number.string2,number.string3,'','',number.title)}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={number.title} /></ListItemButton></ListItem><Divider />
</span>);

let myBilling = numbersBilling.map((number, index) => <span key={number.id}> <ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); updateDisplay((number.inid == 0 ? number.itemId : number.inid),number.addnew,number.string1,number.string2,number.string3,'','',number.title)}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={number.title} /></ListItemButton></ListItem><Divider />
</span>);

let myAccountSettings = numbersAccountSettings.map((number, index) => <span key={number.id}> <ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); updateDisplay((number.inid == 0 ? number.itemId : number.inid),number.addnew,number.string1,number.string2,number.string3,'','',number.title)}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={number.title} /></ListItemButton></ListItem><Divider />
</span>);

return (
<section className='mb-4'>{formState.display}
<PageTitle title='My Account' />
<section className='d-flex'>

<section className='sideBar sideBarBg d-none d-md-block'>
<List component="nav" aria-label="secondary mailbox folder">
<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel3bh-content"
id="panel3bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
<ManageAccountsIcon sx={{ mr: 1, }} />Account Settings
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myAccountSettings}</AccordionDetails></Accordion>



{curUser && curUser.typeId == 0 ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
<AdminPanelSettingsIcon sx={{ mr: 1, }} />Admin Tasks
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
{adminMenu}
</AccordionDetails>
</Accordion> : null}

{curUser && curUser.typeId == 3 ? <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel2bh-content"
id="panel2bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>
<Inventory2Icon sx={{ mr: 1, }} />My Products &amp; Services 
</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myProducts}</AccordionDetails>
</Accordion> : null}

{curUser && (curUser.typeId == 3) ? <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel4bh-content"
id="panel4bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>
<MoneyIcon sx={{ mr: 1, }} />Billing 
</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myBilling}</AccordionDetails>
</Accordion> : null}
</List>
</section>
<section className='flex-fill pt-1 px-3'>
{(parseInt(formState.editData) != -1 && parseInt(formState.editData) != -2) ? <><ListData pageTitle={formState.pageTitle} editState={-1} menu={props.menu} display={formState.display} addnew={formState.addnew} dataimport={formState.dataimport} dataexport={formState.dataexport} manage={formState.manage} updateDisplay={updateDisplay} /></> : parseInt(formState.editData) == -2 ? <><EditForm rowid={formState.addnew} formmode={2} tbl={formState.display} viewid={1} updateDelete={updateDelete} title='get name' type="form" menu={props.menu} /></> : <><EditForm rowid={formState.addnew} formmode={2} tbl={formState.display} viewid={2} updateDelete={updateDelete} type="form" menu={props.menu} domain={formState.domain} fullname={formState.fullname}  password={formState.password} city={formState.city} address={formState.address} province={formState.province} username={formState.username} zip={formState.zip} string1={formState.string1} string2={formState.string2} string3={formState.string3} string4={formState.string4} string5={formState.string5} string6={formState.string6} string7={formState.string7} string8={formState.string8} string9={formState.string9} string10={formState.string10} string11={formState.string11} string12={formState.string12} string13={formState.string13} string14={formState.string14} string15={formState.string15} string16={formState.string16} string17={formState.string17} string18={formState.string18} string19={formState.string19} string20={formState.string20} string21={formState.string21} string22={formState.string22} string23={formState.string23} string24={formState.string24} string25={formState.string25} string26={formState.string26} string27={formState.string27} description={formState.description} /></>}


</section>
</section>
</section>
);
}