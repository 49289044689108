/* eslint-disable */ 
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {SocialConnect, GetPageTitles} from '../../components/utility/utility';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
//import { DEF_SITE_LIST } from '../../components/gpl/gpl';
//import {useQuery} from '@apollo/client';
//import FolderOpenIcon from '@mui/icons-material/FolderOpen';


export default function Footer(props) {  //pages.data.pages.nodes
const [bannerOpen, setBannerOpen] = React.useState(true);
const closeBanner = () => { setBannerOpen(false); };

const numbersFooter = props.menu.getSiteList.filter(function(currItem) { return (parseInt(currItem.planid) == 122 || parseInt(currItem.planid) == 120 || parseInt(currItem.itemId) == 133) && ((currItem.inid == 0 ? currItem.itemId : currItem.inid) != 14) && ((currItem.inid == 0 ? currItem.itemId : currItem.inid) != 12) && ((currItem.inid == 0 ? currItem.itemId : currItem.inid) != 124) && ((currItem.inid == 0 ? currItem.itemId : currItem.inid) != 13); });

function setCookieAccept(){ 
//sessionStorage.setItem("acceptCookie", 'yes');
if (
!document.cookie
.split("; ")
.find((row) => row.startsWith("dredgeCookie"))
) { document.cookie = "dredgeConsent=true; path=/; max-age=234000; SameSite=strict";
}
}

const cookieValue = document.cookie
  .split("; ")
  .find((row) => row.startsWith("dredgeConsent="))
  ?.split("=")[1];
  
let footMenu = numbersFooter.map((number, index) => <section key={number.id+''+window.uuidv4()} className="col-md-4 col-lg px-5 mb-3"><section className="d-flex align-items-center"><section className='fw-bold footHeader mb-3 defText'>{number.title}</section>
</section>							  
<GetPageTitles products={props.products} pages={props.pages} classes='aboutLinks d-block pb-3 mb-3' menuId={number.inid ==0 ? number.itemId : number.inid} /></section>);

return (
<>
<section className='footerCon pt-3 px-5'>
<section className='text-center text-white fw-bold m-3'>All Products and Services Guaranteed</section>
<section className="mt-3 mb-3 footerTopDiv pt-3 pb-2">
<section className="row my-3">
{footMenu}
</section>
</section>


<section className='defPad footerTopDiv'>
<section className="d-flex">

<section className="flex-fill px-4">
<section className="d-flex justify-items-center align-items-center">
<section className="flex-fill px-3 mt-3 mb-3">
<section className="text-center">Connect: <SocialConnect /></section>
</section>
</section>
</section>
</section>
</section>


<section className='d-flex align-items-center footerTopDiv mt-2 pt-1 flex-wrap'>
<Typography className='copy1 mt-2 mb-2'>&copy; {new Date().getFullYear()}. Dredge Resource Limited. #11 Hillary Emezue Street, Dawaki Layout, Abuja, Nigeria.<br />All Rights Reserved. This website is owned and operated by Dredge Resources Limited, a registered Nigerian LLC. Trademarks and Logos used are the properties of their respective owners and are only used for references and descriptive purposes. By using this site, you signify that you agree to be bound by our <Link to={`/con/476/131/Terms and Legal Agreements/123`} state={{ pageTitle : `Terms and Legal Agreements` }} className="aboutLinks">
Universal Terms of Service</Link>
  </Typography>
</section>
</section>

<div className="scrollUp" onClick={ () => window.topTop() }>
<Tooltip title="Back to top" arrow={true} >
<IconButton><ArrowDropUpIcon sx={{ fontSize: 30, color: '#ffffff', fontWeight:'bold', }} />
</IconButton></Tooltip>
</div>


{!cookieValue ? <React.Fragment>
      <CssBaseline />
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
			  backgroundColor:'#0080C0',
			  color:'#ffffff',
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ justifyContent: 'space-between', gap: 2 }}
            >
              <Box
                sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  This website uses cookies
                </Typography>
                <Typography variant="body2">
                  We relies on cookies to improve your experience. Read our <Link onClick={ () => window.topTop() } to={{ pathname: "/con/143/131/Cookie Policy/123",}} className="secondaryMenu"><u>Cookie Policy</u></Link>

                </Typography>
              </Box>
              <Stack
                direction={{
                  xs: 'row-reverse',
                  sm: 'row',
                }}
                sx={{
                  gap: 2,
                  flexShrink: 0,
                  alignSelf: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <Button size="small" color='warning' onClick={() => { closeBanner(); setCookieAccept();}} variant="contained">
                  Got it
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
    </React.Fragment> : null}
</>
);}
