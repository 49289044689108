/* eslint-disable */ 
import { useParams } from 'react-router-dom';
import {PageTitle, LoadingSK, RigthPromo} from '../../components/utility/utility';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import Form from '../../components/form/form';


export default function App(props) { 
const {gid, title, id, menuid} = useParams();
const details = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:String(gid), arg2: parseInt(id) }, fetchPolicy: "cache-and-network" }); 
let getDetails = <LoadingSK />;

if(details.error){getDetails = <section className="alert alert-warning offline" role="alert">Connection Error! {details.error.message}</section>;} 
if(details.data){
if(details.data.getSiteList.length > 0){
getDetails = <section dangerouslySetInnerHTML={{__html: details.data.getSiteList[0].string3}}></section>;} else {getDetails = '';}
}
return (
<section className='mt-3 mb-4 defMrg'>
<section className='content'>
<section className='mb-2'><PageTitle title={title} /></section>
<section className='row'>
<section className='col-md-12'>
{(menuid && parseInt(menuid) == 126) ? <section className='m-5'><Form type="form" viewid={24} formmode={1} /></section> : getDetails}

</section>
</section>
</section>
</section>
);}
