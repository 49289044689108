/* eslint-disable */ 
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import {DropMenu, Currency} from '../../components/utility/utility';
import Form from '../../components/form/form';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
//import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import LoginIcon from '@mui/icons-material/Login';
import Badge from '@mui/material/Badge';

import { styled, useTheme } from '@mui/material/styles';
import { AddToCart } from '../../components/utility/utility';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';


import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';							 
import Typography from '@mui/material/Typography';






export default function Header(props) { 
//let location = useLocation();
const numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 1; });
const numbersPrimary = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 115; });

let totalAmount = 0;
const StyledBadge = styled(Badge)(({ theme }) => ({
'& .MuiBadge-badge': {
// right: -3,
top: 6,
//  border: `2px solid ${theme.palette.background.paper}`,
//  padding: '0 4px',
},
}));

let listItems = numbers.map((number, index) => <Link key={number.id} to={`/acct/${(number.inid === 0) ? number.itemId : number.inid}/${number.title}`} state={{ pageTitle : number.title }} className="secondaryMenu mx-2">
{(parseInt(number.inid) == 111 || parseInt(number.itemId) == 111) ? <>
{props.cartItems.length > 0 ? <>
<StyledBadge badgeContent={props.cartItems.length} color="secondary" anchorOrigin={{
vertical: 'top',
horizontal: 'left',
}}><ShoppingCartIcon sx={{ marginRight:0, }} /></StyledBadge>
</> : 
<ShoppingCartIcon sx={{ marginRight:0, }} /> }
</> : null}
{(parseInt(number.inid) == 108 || parseInt(number.itemId) == 108) ? <PeopleIcon sx={{ marginRight:1, }} /> : null}
{(parseInt(number.inid) == 109 || parseInt(number.itemId) == 109) ? <LoginIcon sx={{ marginRight:1, }} /> : null}
{number.title}</Link>);


let primaryMenu = numbersPrimary.map((number, index) => <span key={number.id} className='mx-1 d-none d-md-block d-lg-block'><DropMenu title={number.title} products={props.products} groupId={number.inid == 0 ? number.itemId : number.inid} /></span>);

let primaryMenuMobile = numbersPrimary.map((number, index) => <span key={number.id} className='mx-1 d-block d-md-none d-lg-none'><DropMenu title={number.title} products={props.products} groupId={number.inid == 0 ? number.itemId : number.inid} /></span>);

if(props.cartItems && props.cartItems.length > 0){
for (let i = 0; i < props.cartItems.length; i++) { 
totalAmount = totalAmount+((Number(props.cartItems[i].description))*(Number(props.cartItems[i].total)));
}}
	
return (
<section className='mb-5'>
<section className="topMenuRow">
<section className="d-flex align-items-center p-1">
<span className="mx-2"><Currency currency={props.currency} setCountry={props.setCountry} value={10} color='#ffffff' /></span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center">
{sessionStorage.getItem("signIn") ? <><span className=''>
<AccountMenu /></span>


<Link to={`/acct/111/Cart`} state={{ pageTitle : 'Shopping Cart' }} className="secondaryMenu mx-2">
{props.cartItems.length > 0 ? <>
<StyledBadge badgeContent={props.cartItems.length} color="secondary" anchorOrigin={{
vertical: 'top',
horizontal: 'left',
}}><ShoppingCartIcon sx={{ marginRight:0, }} /></StyledBadge>
</> : 
<ShoppingCartIcon sx={{ marginRight:0, }} /> }
</Link>
</>
 : <>{listItems}</>}
</div>
</section>
</section>
</section>

<section className="pt-2 pb-2 defMrg topMenuRow2">
<section className="d-flex align-items-center">
<section className='logoTopCon d-none d-sm-block d-md-block d-lg-block'>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: `/`, }} className="secondaryMenu"><img loading='lazy' src={Logo} className="img-fluid" /></Link>
</section>

<section className="flex-grow-1 flex-fill d-flex justify-content-center align-self-center">
<Form type="form" dropDown={props.dropDown} viewid={22} formmode={1} /></section>

<section className="d-flex flex-column d-none d-md-block d-lg-block">
<section className="topMenu1 fw-bold mb-2 flex-fill d-flex flex-row-reverse"> 
<Button variant="contained" sx={{ fontSize: 11, backgroundColor:'#D24400', fontWeight:'bold', color:'#ffffff' }}>Total: {<AddToCart type='hosting' currency={props.currency} price={totalAmount} />}</Button>
</section>
</section>
</section>
</section>


<section className="sticky-top bg-white mt-2 defPad mb-1">
<section className="d-flex">
<section className="flex-fill d-flex">{primaryMenu}</section>
<section className="flex-grow-1">
<section className="mb-2 flex-fill d-flex flex-row flex-row-reverse align-items-end">
<span className='d-block d-md-none d-lg-none'><ResponsiveDrawer products={props.products} menu={numbersPrimary} /></span> 
</section>
</section>
</section>
</section>
</section>
);}



function AccountMenu() {
const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};

 return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin:0, padding:0, }}>
        <Tooltip title="My Account">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar  sx={{ fontSize: 12, width: 20, height: 20 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
<MenuItem onClick={handleClose}>
<Avatar /><Link className='myAcct' onClick={ () => window.topTop() } to={{ pathname: "/acct/114/myacct"}} state={{ pageTitle : 'My account' }}>My account </Link>
</MenuItem>
<MenuItem onClick={handleClose}>
<ListItemIcon>
<Logout fontSize="small" />
</ListItemIcon>
<Link className='myAcct' onClick={ () => window.topTop() } to={{ pathname: "/logout"}}>Logout</Link>
</MenuItem>
</Menu>
</React.Fragment>
);
}


function ResponsiveDrawer(props) {
const theme = useTheme();
const [open, setOpen] = React.useState(false);

const handleDrawerOpen = () => {
setOpen(true);
};

const handleDrawerClose = () => {
setOpen(false);
};

const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

function getLinks(x){
let numbers = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == x; });
let listItems = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={number.id} className='p-2 sideMenuMobile'>
<Typography><Link to={{ pathname: `/cat/${x}/${number.inid == 0 ? number.itemId : number.inid}/${number.string1}` }}  state={{ pageTitle : `${number.string1} ${x ==  118 ? ' VPS' : ''} ${x ==  117 ? ' Web Hosting' : ''}`, }} onClick={ () => { window.topTop(); handleDrawerClose(); }}
className="sideMenu d-block px-2">{number.string1}</Link>
</Typography>
</section> 
);
return (listItems);
}
  
let mobileMenu = props.menu.map((number, index) => <span key={'mobilemenu'+number.id} className='mx-1 '>
<Accordion expanded={expanded === ('panel'+index)} onChange={handleChange('panel'+index)}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls={"panelbh-content"+index}
id={"panelbh-header"+index}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{getLinks(number.inid == 0 ? number.itemId : number.inid)}
</AccordionDetails></Accordion>
</span>);

  
  const drawerWidth = 240;
  const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));



return (
<span>
<CssBaseline />
<IconButton
color="inherit"
aria-label="open drawer"
onClick={handleDrawerOpen}
sx={{ m: 0, ...(open && { display: 'none' }) }}
>
<MenuIcon />
</IconButton>
<Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>	
{mobileMenu}
</Drawer>
</span>
  );
}