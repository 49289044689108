/* eslint-disable */ 
import { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Form from '../../components/form/form';
import { ProductFeatures } from '../../components/utility/utility';
import Button from '@mui/material/Button';


import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import {AddToCart} from '../../components/utility/utility';

export default function App(props) { 
const {catid, id} = useParams();
const theme = useTheme();
const [value, setValue] = useState(0);
const handleChange = (event, newValue) => {
setValue(newValue);
};










const numbersHosting = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117; });
const numbersServers = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 118; });

let hostingFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 117 ||parseInt(currItem.planid) == 0; });
let serverFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 118 ||parseInt(currItem.planid) == 0; });

hostingFeatures = hostingFeatures.sort((a, b) => a.total - b.total);
serverFeatures = serverFeatures.sort((a, b) => a.total - b.total);









function passPrdId(prdId){
let listhostingFeatures = hostingFeatures.map((number, index) => 
<section key={window.uuidv4()+''+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
{getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId)} {number.zip}
</section>
</section>
</section>
);
return listhostingFeatures;
}

function passServId(prdId){
let listserverFeatures = serverFeatures.map((number, index) => 
<section key={window.uuidv4()+''+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
<span className='text-end'>{getFeatureValue(numbersServers,(number.fullname), serverFeatures, prdId)} {number.zip}</span>
</section>
</section>
</section>
);
return listserverFeatures;
}

function getFeatureValue(x,y,z,productid){
let colN = y;
function findNamesByFavoriteFood(list, favoriteFood) {
let fitem = list.filter((obj) => obj[colN] != '');//.map((obj) => obj.name);
let sitem = fitem.filter((obj) => (obj.itemId == productid || obj.inid == productid)).map((obj) => obj[colN]);
return sitem;
}
return (findNamesByFavoriteFood(x, y));
}

function productHeaderClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('bg-success text-white p-2 text-center');
case 1:  
return ('bg-primary text-white p-2 text-center');
case 2:  
return ('bg-info text-white p-2 text-center');
case 3: 
return ('bg-secondary text-white p-2 text-center');
default:
return ('bg-primary text-white p-2 text-center');
}
}

function productServersClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('server1 text-white p-2 text-center');
case 1:  
return ('server2 text-white p-2 text-center');
case 2:  
return ('server3 text-white p-2 text-center');
case 3: 
return ('server4 text-white p-2 text-center');
default:
return ('server5 text-white p-2 text-center');
}
}































let useId = 0;
if(parseInt(catid) == 116){useId=209}
if(parseInt(catid) == 117){useId=215}
if(parseInt(catid) == 118){useId=216}
if(parseInt(catid) == 7){useId=217}
if(parseInt(catid) == 120){useId=357}
const numbersDomain = props.pages.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid == 0 ? currItem.itemId : currItem.inid) == useId) ; });
const numbersProducts = props.products.getSiteList.filter(function(currItem) { return (parseInt(currItem.string5) == parseInt(catid)) ; });

let listItems = numbersProducts.map((number, index) =>
// Correct! Key should be specified inside the array. 
<Tab key={'productHeader'+number.id} label={number.string1} {...a11yProps(index)} />
);

function getDetails(id=0,type='title'){
let details = props.products.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid == 0 ? currItem.itemId : currItem.inid) == parseInt(id)) ; });
if(details.length > 0){
if(type=='teaser'){return (details[0].string2);}
if(type=='body'){return (details[0].string3);}
return (details[0].string1);
}
return '';
}

let listDetails = numbersProducts.map((number, index) =>
// Correct! Key should be specified inside the array. 
<TabPanel key={'productDetails'+number.id} value={value} index={index} dir={theme.direction}>
<section className='mb-3 pageTitle fw-bold'>{getDetails((number.inid == 0 ? number.itemId : number.inid))}</section>
<section className='mb-3' dangerouslySetInnerHTML={{__html: getDetails((number.inid == 0 ? number.itemId : number.inid), 'body')}}></section>
<section className='mb-3' dangerouslySetInnerHTML={{__html: getDetails((number.inid == 0 ? number.itemId : number.inid), 'teaser')}}></section>
{parseInt(catid) == 120 ? <section className='d-flex justify-content-center m-5 p-4'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></section> : null}
</TabPanel>
);


let listHosting = numbersHosting.map((number, index) => 
<section className='col-sm-6 col-lg-3 px-3 mb-5' key={window.uuidv4()+''+number.id}>
<Card>
<CardActionArea>
<h5 className={productHeaderClass(index)}>{number.string1}</h5>
<CardContent>
<section className='mb-3 mt-2 text-center'>
<AddToCart prdname={`${number.string1} Web Hosting`} type='hosting' cart currency={props.currency} itemId={number.inid == 0 ? number.itemId : number.inid} price={number.string4} updateCart={props.updateCart} />
</section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passPrdId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</section>
);

let listServers = numbersServers.map((number, index) => 
<section className='col-sm-6 col-lg-3 px-3 mb-5' key={window.uuidv4()+''+number.id}>
<Card>
<CardActionArea>
<h5 className={productServersClass(index)}>{number.string1}</h5>
<CardContent>
<section className='mb-3 mt-2 text-center'>
<AddToCart prdname={`${number.string1} VPS`} type='server' cart currency={props.currency} itemId={number.inid == 0 ? number.itemId : number.inid} price={number.string4} updateCart={props.updateCart} />
</section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passServId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</section>
);

return (
<section className='mb-4 defMrg'>
<section className='mb-5 pb-3' dangerouslySetInnerHTML={{__html: numbersDomain[0].string4}}></section>
{parseInt(catid) == 117 ? <section className='row'>{listHosting}</section> : null}
{parseInt(catid) == 118 ? <section className='row'>{listServers}</section> : null}


{parseInt(catid) == 116 ? <section className='d-flex justify-content-center m-5'><Form type="form" dropDown={props.dropDown} viewid={22} formmode={1} /></section> : null}


{parseInt(catid) == 116 ? <section className=''><h4 className='mb-4 fw-bold text-center'>What you get with every domain purchase</h4>
<section className='row m-5'><ProductFeatures gId={catid} col={true} free={true} /></section>
</section> : null}

{parseInt(catid) == 118 || parseInt(catid) == 117 ? <>
<h5 className='mt-5 fw-bold pb-1'>Every package includes</h5>
<section className="row consultancy p-3">
<ProductFeatures gId={catid} col={true} />
</section>
<section></section>
<section></section>
</> : null}



{parseInt(catid) == 120 ? <section className='m-5'>
<Box>
<Tabs
value={value}
onChange={handleChange}
variant="scrollable"
scrollButtons="auto"
aria-label="scrollable auto tabs example"
className='consultancy fw-bold'
>{listItems}</Tabs>{listDetails}
</Box></section> : null}
</section>
);
}


function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`full-width-tabpanel-${index}`}
aria-labelledby={`full-width-tab-${index}`}
{...other}
>
{value === index && (
<Box sx={{ p: 3 }}>
<section>{children}</section>
</Box>
)}
</div>
);
}

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `full-width-tab-${index}`,
'aria-controls': `full-width-tabpanel-${index}`,
};
}